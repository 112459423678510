<template>
  <b-form-group
    label="Girişimci Davet Bağlantısı"
    label-for="invite_code"
  >
    <b-input-group>
      <b-form-input
        id="invite_code"
        ref="linkInput"
        v-model="dataItem.invite_code"
        placeholder="Girişimci Davet Bağlantısı"
        readonly
      />
      <b-input-group-append>
        <b-button
          variant="success"
          @click="copyToClipboard"
        >
          <FeatherIcon icon="CopyIcon" />
          Kopyala
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  name: 'InviteCode',
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealerAccount/dataItem']
    },
  },
  methods: {
    copyToClipboard() {
      const inputElement = this.$refs.linkInput
      inputElement.select()
      document.execCommand('copy')
      inputElement.setSelectionRange(0, 0)
    },
  },
}
</script>
