<template>
  <b-tabs
    pills
  >
    <b-tab>
      <template #title>
        <feather-icon icon="InfoIcon" />
        <span>Bayi Bilgileri</span>
      </template>
      <dealer-card />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ShoppingCartIcon" />
        <span>Siparişlerim</span>
      </template>
      <orders-card />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import DealerCard from '@/views/Dealers/Account/Forms/DealerCard.vue'
import OrdersCard from '@/views/Dealers/Account/Forms/OrdersCard.vue'

export default {
  name: 'Index',
  components: {
    BTabs,
    BTab,
    DealerCard,
    OrdersCard,
  },
}
</script>
