<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <label>Paket Kalan Kullanım</label>
      <b-progress
        :max="100"
      >
        <b-progress-bar :value="itemData.usable_rate">
          <strong>{{ itemData.used_reports }} / {{ itemData.usable_reports }}</strong>
        </b-progress-bar>
      </b-progress>
    </b-card>
    <b-card>
      <b-row>
        <b-col cols="12">
          <company />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <tax-number />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <tax-office />
        </b-col>
        <b-col cols="12">
          <address-text />
        </b-col>
        <b-col cols="12">
          <invite-code />
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </validation-observer>
</template>

<script>
import {
  BCard, BProgress, BProgressBar, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Company from '@/views/Dealers/Account/Forms/elements/Company.vue'
import TaxNumber from '@/views/Dealers/Account/Forms/elements/TaxNumber.vue'
import TaxOffice from '@/views/Dealers/Account/Forms/elements/TaxOffice.vue'
import AddressText from '@/views/Dealers/Account/Forms/elements/Address.vue'
import InviteCode from '@/views/Dealers/Account/Forms/elements/InviteCode.vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    Company,
    TaxNumber,
    TaxOffice,
    AddressText,
    InviteCode,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['dealerAccount/dataItem']
    },
    saveData() {
      return this.$store.getters['dealerAccount/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    localize(this.$i18n.locale)
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('dealerAccount/getData')
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('dealerAccount/saveData', this.itemData)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
