<template>
  <b-card no-body>
    <b-card-text>
      <b-table
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(onum)="data">
          <div>{{ data.item.onum }}</div>
        </template>
        <template #cell(order_status)="data">
          {{ data.item.order_status }}
        </template>
        <template #cell(control)="data">
          <b-button
            :to="'/dealers/account/order/' + data.item.id"
            variant="primary"
            size="sm"
          >
            <FeatherIcon icon="EyeIcon" />
            Görüntüle
          </b-button>
        </template>
      </b-table>
    </b-card-text>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCount"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BCardFooter,
    BPagination,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'onum',
          label: 'SİPARİŞ NO',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'order_status',
          label: 'DURUM',
          thClass: 'text-nowrap width-200',
          tdClass: 'text-nowrap width-200',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-100',
          tdClass: 'text-nowrap width-100',
        },
      ],
      dataQuery: {
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['dealerOrders/dataList']
    },
    dataCount() {
      return this.$store.getters['dealerOrders/dataCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('dealerOrders/getDataList', this.dataQuery)
    },
  },
}
</script>
